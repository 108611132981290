<template>
  <section>
    <div class="form">
      <h2>{{ content.contacts.title }}</h2>
      <div>
        <v-text-field
          v-model="name"
          :label="content.contacts.label_name + '*'"
          dense
        ></v-text-field>
        <v-text-field
          v-model="email"
          :label="content.contacts.label_email + '*'"
          dense
        ></v-text-field>
        <v-text-field
          v-model="phone"
          :label="content.contacts.label_phone + '*'"
          dense
        ></v-text-field>
        <v-text-field
          v-model="company"
          :label="content.contacts.label_company"
          dense
        ></v-text-field>
        <v-textarea
          :label="content.contacts.label_message"
          v-model="message"
          rows="3"
        ></v-textarea>
      </div>
      <p>{{formMsg}}</p>
      <a class="button-fill" @click="send_mail">{{ content.contacts.btn_form }}</a>
      <div class="address">
        <p>Auris–Consulting & Investment, S.A.<br>CHE–198.378.126</p>
        <p>{{ content.contacts.address_name }}</p>
        <p>{{ content.contacts.address }}</p>
        <p>{{ content.contacts.zip_code }}</p>
        <p><a :href="'mailto:' + content.contacts.email">{{ content.contacts.email }}</a></p>
      </div>
    </div>
    <div class="map">
      <a target="_blank" href="https://www.google.com/maps/place/Rue+de+la+Cit%C3%A9,+1204+Gen%C3%A8ve,+Su%C3%AD%C3%A7a/@46.2031566,6.141793,17z/data=!3m1!4b1!4m5!3m4!1s0x478c652971338b0b:0x27121db028260dbb!8m2!3d46.2031566!4d6.1439817"><div :style="{ backgroundImage: 'url(' + img_url + 'contacts.jpg' + ')' }"></div></a>
    </div>
  </section>
</template>

<script>
import computedMixins from "@/mixins/computed";
import methodsMixins from "@/mixins/methods";

import api_calls from '@/modules/api_calls'

export default {
  mixins: [computedMixins, methodsMixins],
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      company: "",
      message: "",
      formMsg: ""
    };
  },
  methods: {
    async send_mail() {

      this.formMsg = "Sending..."

      if(this.name == "" || this.email == "" || this.phone == "" || this.company == "" || this.message == "") {

        this.formMsg = "All fields marked with * are mandatory"

        return false

      }

      let mailObj = {
        name: this.name,
        email: this.email,
        phone: this.name,
        company: this.company,
        message: this.message,
      }

      let res = await api_calls.call_api_post('mail/contactos', mailObj)

      console.log(res)

      if(res["resultado"] == true) {
        this.formMsg = "Your message was sent! We will get back at you shortly"
      }
      else {
        this.formMsg = "Something went wrong... Please try again later or send an email directly to auris@auriscollection.com"
      }

    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/style.scss";

section{
    
}
.form {
  width: 600px;
  display: inline-block;
  padding: 60px 70px 0 200px;
  vertical-align: top;
  @include responsive(0, 1024px){
      width: 100%;
      max-width: 700px;
      padding: 60px 70px 60px 40px;
  }
  @include responsive(0, 512px){
      width: 100%;
      max-width: 700px;
      padding: 60px 20px 30px 20px;
  }
  h2{
      color: $dark-grey;
      text-align: left;
      margin-bottom: 30px;
  }
  .button-fill{
      width: 200px;
       margin-top: 10px;
  }
  .address{
      margin-top: 20px;
      p{
          text-align: left;
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          a{
              color: $dark-grey;
          }
      }
      p:nth-child(1){
          font-weight: 300;
          font-size: 13px;
          margin-bottom: 10px
      }
      p:nth-child(2){
          font-weight: 300;
      }
      p:nth-child(4){
          margin-bottom: 10px;
      }
  }
}
.map {
  width: calc(100% - 600px);
  display: inline-block;
  vertical-align: top;
  @include responsive(0, 1024px){
      width: 100%;
  }
  a{
      display: block;
      width: 100%;
      flex:1;
      div{
      width: 100%;
      height: 100vh;
      max-height: 700px;
      background-position: center right;
      background-size: cover;
      @include responsive(0, 1024px){
          height: 80vh;
      }
  }
  }
}
</style>